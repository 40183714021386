import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import sports from './reducer_sports';
import matches from './reducer_matches';
import match from './reducer_match';
import matchInfo from './reducer_match_info';
import categories from './reducer_categories';
import leagueTables from './reducer_league_tables';

export default combineReducers({
    router: routerReducer,
    sports,
    matches,
    match,
    matchInfo,
    categories,
    leagueTables
});
