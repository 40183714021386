import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMatches } from '../../actions/actions_match';
import Match from './widget_match';
import $ from "jquery";

let interval;
let div = '';
let stop = true;

class Widget extends Component {
	componentDidMount() {
        var offset = new Date().getTimezoneOffset();
		let sport = this.props.match.params.sport || 'soccer';
		let d = new Date();
		let dateString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
		this.props.getMatches(sport, dateString, offset);

		div = $('#widget');
    scroll(div);

    div.mouseover(function() {
        if (stop) {
            clearInterval(interval)
        }
    });

    div.mouseleave(function() {
        if (stop) {
            scroll(div);
        }
    });
	}

	renderMatches = () => {
		let matches = [];
    var lastCount = 0;
    let tournaments = this.props.matches.tournaments || [];
    let allMatches = this.props.matches.matches || [];

    tournaments.forEach(function(tournament, index) {
    	let categoryName = tournament.tournament.category.name;
      let count = tournament.count;

      let rows = allMatches.slice(lastCount, lastCount+count).map(function(match, index) {
        return (
              <Match key={'a'+match.id} match={match} />
          );
      });

      matches[index] = <article className="module" key={tournament.tournament.id}>
												<header className={this.props.favorite}>
									        <span className={categoryName.replace(/\s+/g, '-').toLowerCase().replace('-amateur', '').replace('-youth', '').replace('-clubs', '')}></span>
									      	{categoryName.replace('International', 'Int.')}
									        <span> - </span>
									        {tournament.tournament.name.replace('UEFA', '')}
									      </header>
												<ul>{rows}</ul>
											</article>

      lastCount += count;
    }.bind(this));

    return matches;
	}

	render() {
		return (
			<div>
				<div className="pageHeader" style={{height: '37px',position: 'relative', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', boxShadow: 'inset 0 -3px 10px 0 rgba(0, 0, 0, 0.2)'}}>
	       		<a href="//sportlive.co" target="_blank" rel="noopener noreferrer"  style={{ width: '140px'}}>
	        	<img src="/images/assets/goalwire-logo.png" alt="goalwire" style={{height: '25px', width: 'auto', padding: '6px'}} />
        		</a>
	    	</div>
				<div id="widget" style={{overflowY: 'auto', height: '310px'}}>
		    	  <div id='widget-events'>
		      		{this.renderMatches()}
		    	</div>
		    </div>
      </div>
		);
	}
}

function scroll(div) {
    interval = setInterval(function() {
        var pos = div[0].scrollTop;
        pos += 1;
        div.scrollTop(pos);
        if (div[0].scrollHeight - div[0].scrollTop <= div.outerHeight()) {
            stop = false;
            clearInterval(interval);
            setTimeout(function() {
                div.animate({
                    scrollTop: 0
                }, 1000, function() {
                    setTimeout(function() {
                        div[0].scrollTop = 0;
                        scroll(div);
                        stop = true;
                    }, 1000);
                });
            }, 1000);
        }
    }, 40);
}

function mapStateToProps(state) {
  return {
    matches: state.matches
  }
}

export default connect(mapStateToProps, { getMatches })(Widget);


