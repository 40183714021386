import React from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { Route, Switch } from 'react-router-dom'
import { Router } from 'react-router'
import store, { history } from './store';
import App from './containers/app';
import League from './containers/league';
import Match from './containers/match';
import Widget from './containers/widget/widget';
import registerServiceWorker from './registerServiceWorker';
import ReactGA from 'react-ga';
import WebFont from 'webfontloader';
//import getGeoInfo from './getGeoInfo';

 
console.log(process.env) // remove this after you've confirmed it is working

WebFont.load({
  google: {
    families: ['Titillium+Web:400,600,700']
  }
});


ReactGA.initialize('UA-148440045-1');

const prod = process.env.NODE_ENV === 'production';
if (prod) {
    history.listen((location, action) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
}
 
 

ReactDOM.render(
 
	<Provider store={store}>
    <Router history={history}>
      <Switch>
       { /* <Route path="/widget/:sport" component={Widget} /> */ }
       { /*  <Route path="/:sport/:teams/:matchId" component={Match} />  */ }
        <Route path="/:sport/:teams/:matchId" component={App} />  
        <Route path="/:sport/:league/:leagueId" component={League} />
        <Route path="/:sport/:date" component={App} />
        <Route path="/:sport" component={App} />
        <Route path="/" component={App} />  
      </Switch> 
    </Router>
  </Provider>,
  document.getElementById('root'));

 
registerServiceWorker();

    
