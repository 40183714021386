export const getSafe = (fn) => {
    try {
        return fn();
    } catch (e) {
        return '';
    }
}

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

 