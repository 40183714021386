import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSports } from '../actions/actions_sports';
import MenuItem from '../components/menu_item.js';
import ReactGA from 'react-ga';

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = { date: this.props.date }
  }

  componentDidMount() {
    this.props.getSports(this.props.date);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.date !== this.state.date) {
      this.setState({date: nextProps.date}, function() {
        this.props.getSports(nextProps.date);
      }.bind(this));
    }
  }


  render() {
    let sports = this.props.sports.slice(0, 8);
    let otherSports = this.props.sports.slice(8, this.props.sports.length);
    let selectedSport = this.props.selectedSport.replace(/\s+/g, '-').toLowerCase();
    let otherActive = '';

    // The sports that go in the dropdown
    otherSports.sort(function(a, b) {
      var nameA = a.name.toLowerCase();
      var nameB = b.name.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0
    });

    otherSports.forEach(function(os) {
      if(selectedSport === os.name.replace(/\s+/g, '-').toLowerCase()) {
        otherActive = 'active';
      }
    });

    return (
      <ul className="drop_menu">
        {
          sports.map(function(sport) {
            return (
              <MenuItem
                key={sport.id}
                sport={sport}
                selectedSport={selectedSport}
                />
            )
          })
        }
        <li className={"dropdown " + otherActive}>
          <span>
            Other <i className="fa fa-caret-down"></i>
          </span>
          <ul>
            {
              otherSports.map(function(sport) {
                return (
                  <MenuItem
                    key={sport.id}
                    sport={sport}
                    selectedSport={selectedSport} />
                )
              })
            }
          </ul>
        </li>
      </ul>
    );
  }
}

Menu.propTypes = {
  sports: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    sports: state.sports
  }
}

export default connect(mapStateToProps, { getSports })(Menu);


