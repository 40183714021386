import { SET_MATCHINFO } from '../actions/actions_match';

export default function match(state = {}, action = {}) {
    switch(action.type) {
        case SET_MATCHINFO:
            return action.matchInfo;
        default: 
            return state;
    }
}
