import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMatch } from '../actions/actions_match';
//import Match from '../components/match';
import MatchDetails from '../components/match_details';
//import PlaceholderMatch from '../components/placeholders/single_match';
//import MatchTournament from '../components/match_tournament';
//import Favorites from '../components/Favorites'
//import DatePicker from '../components/datepicker';
import { AdSidebarLeft, AdSidebarRight } from '../components/adserver/dfp_ads';
//import ListCountries from './list_countries';
//import { Menu } from 'semantic-ui-react'
import Stream from '../components/stream';

//import LiveMenu from '../components/LiveMenu';
//import LeagueTables from './league_table';  

//import ReactLoading from 'react-loading';


class SingleMatch extends Component {
  selectMatch = (match) => {
    this.props.selectMatch(match.id);
  }

  removeHasGoal = (match, callback) => {
    this.props.updateMatch(match);
    callback();
  }
/*
  renderMatches = () => {
    let matches = [];
    var lastCount = 0;
    let tournaments = this.props.matches.tournaments || [];
    let allMatches = this.props.matches.matches || [];
    console.log(this.props.sport)
    tournaments.forEach(function (tournament, index) {
      let count = tournament.count;
      //let count = 2;
      let favorite = '';

      let rows = allMatches.slice(lastCount, lastCount + count).map(function (match, index) {
        return (
          <Match
            key={'a' + match.id}
            match={match}
            removeHasGoal={this.removeHasGoal}
            selectMatch={this.selectMatch}
            selectedMatch={this.props.selectedMatch} />
        );
      }.bind(this));

      matches[index] = <MatchTournament key={tournament.tournament.id} tournament={tournament.tournament} favorite={favorite} matches={rows} />;

      lastCount += count;
    }.bind(this));

    return matches;
  }
*/
  render() {
    const { selectedMatch } = this.props;
    //let tournaments = this.props.matches.tournaments || [];
    let mobileActive = this.props.showMobileMatchDetails === true ? 'mobile-active' : '';


    return (
      <div className="page">
 
        <aside className="sidebar-left">
        { /* <Favorites currentSport={this.props.currentSport} />  */ }
        { /* <ListCountries currentSport={this.props.currentSport} /> */ } 
        <AdSidebarLeft />
        </aside>
   

        <div className="main single-match">
       
          
 


          <div id='events'> 
   
          {
            selectedMatch !== null ?
           
              <MatchDetails
                matchDetails={this.props.selectedMatchDetails}
                match={selectedMatch}
                matchInfo={this.props.matchInfo} 
                showMobileMatchDetails={this.props.showMobileMatchDetails} />
           
                 :
              
                 <div className="no-matches">
                 <div class="ui active centered inline loader"></div>
               </div>
                  /*
                   <PlaceholderMatch/>
                 <div className="loading_matches"></div>
            
                    <div className="no-matches">
                  <div class="ui active centered inline loader"></div>
                </div>
                */
          }
    
          </div>
     
        </div>
{   
        <aside id="sidebar" className={'sidebar-right ' + mobileActive}>
     
          <AdSidebarRight />
        </aside>
 
        }

 
      </div>
    );
  }
}

export default connect(null, { updateMatch })(SingleMatch);

