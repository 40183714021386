import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getMatches, getMatch, getMatchInfo, updateMinutes, updateMatches } from '../actions/actions_match';
//import { matchInfo } from '../actions/v2/actions_match';
import { Helmet } from "react-helmet";
import Menu from './menu';
import Matches from './matches';
import Footer from '../containers/footer';
import '../styles/livescores.css';
//import '../styles/icons.css';
import io from 'socket.io-client';
import ReactGA from 'react-ga';
import * as helpers from '../utils/helpers';
import SwitchLanguage from '../components/switch_language';
import _ from 'lodash';
import i18n from '../i18n';
//import { Route, Switch } from 'react-router-dom';
//import LocalizedStrings from 'react-localization';
import {
  MobileView,
  // BrowserView
} from "react-device-detect";
//import { strict } from 'assert';
 
 /*
let strings = new LocalizedStrings({
  en:{
    siteName: "SportLive",
    base: "SportLive",
    home_desc: "Sport Live Score",
    leagues: "Leagues",
  },
  it: {
    base: "SportLive",
    home_desc: "Sport Live Score",
    leagues: "Leagues",
  }
 });
 */

ReactGA.initialize('UA-148440045-1');
const prod = process.env.NODE_ENV === 'production';
let socket = null;
var base = window.location.protocol + "//" + window.location.hostname;
var siteName = i18n.t('metas.title');

if (!prod) {
  if (window.location.protocol === 'https:') {
   // socket = io('https://www.goalwire.com', {secure: true, transports: ['websocket']});
    socket = io('http://159.69.138.110:5000/', {secure: true, transports: ['websocket']});
  } else {
   // socket = io('http://www.goalwire.com', {transports: ['websocket']});
    socket = io('http://159.69.138.110:5000/', {transports: ['websocket']});
  }
} else {
  //socket = io('http://localhost:3000', {transports: ['websocket']});
 // socket = io('http://www.goalwire.com', {transports: ['websocket']});
  socket = io('http://159.69.138.110:5000/', {transports: ['websocket']});
}

class App extends Component {
  constructor(props) {
    super(props);

    let d = new Date();
    let now = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();

    let sport = this.props.match.params.sport || 'soccer';
    let date = this.props.match.params.date || now;
    let matchId = this.props.match.params.matchId || null;
    let teams = 'Livescore';
    if (this.props.match.params.teams) {
      teams = helpers.toTitleCase(this.props.match.params.teams.replace('-', ' ')) + ' / ';
    }

    // var socket = io();
    // socket.on('events', MainActions.updateMatchInfo(events));
    // socket.on('minutes', MainActions.updateMatchMinutes(minutes.events));
    // socket.on('error', function(){
    //     socket.socket.reconnect();
    // });

    socket.on('data', data => {
      console.log(data);
    });
    socket.on('events', data => {
      if(data.minutes) {
        this.props.updateMinutes(data);
      }
      if(data.eventUpdates) {
        this.props.updateMatches(data.eventUpdates);
      }
    });

    if (prod) {
      ReactGA.set({ page: this.props.location.pathname });
      ReactGA.pageview(this.props.location.pathname);
    }

    let title = teams + ' - ' + helpers.toTitleCase(sport.replace('-', ' ')) + ' - ' +  date;


    this.state = {
      date: date,
      currentSport: sport,
      showMobileMatchDetails: false,
      matchesLoaded: false,
      matchId: matchId,
      selectedMatch: null,
      title: title
    }
  }
 

  componentDidMount() {
    var offset = new Date().getTimezoneOffset();
    const { matchId } = this.state;
    this.props.getMatches(this.state.currentSport, this.state.date, offset, function(matches) {
      let selectedMatch = null;
 
     selectedMatch = _.find(matches.matches, function(m) {
        return parseInt(m.id, 10) === parseInt(matchId, 10);
      });

      if (!selectedMatch || selectedMatch === null) {
        selectedMatch = _.find(matches.matches, function(m) {
          return m.has_events === true;
        });
      }

      if (selectedMatch !== null) {
        this.props.getMatch(selectedMatch.id);
        this.props.getMatchInfo(selectedMatch.id);
      }

      this.setState({
        matchesLoaded: true,
        selectedMatch: selectedMatch
      });
    }.bind(this));

    socket.on('connect', () => {
      this.props.getMatches(this.state.currentSport, this.state.date, offset, function() {
        this.setState({matchesLoaded: true});
      }.bind(this));
    });
  }

  componentWillReceiveProps(nextProps) {
    var offset = new Date().getTimezoneOffset();
    let sport = nextProps.match.params.sport;
    if(sport !== undefined) {
      if(sport !== this.state.currentSport) {
        let d = new Date();
        let now = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        let title = helpers.toTitleCase(sport.replace('-', ' '));
        this.props.getMatches(sport, now, offset, function() {
          this.setState({
            currentSport: sport,
            date: now,
            title: title
          })
        }.bind(this));
      }
    }
  }

  onChangeDate = (date) => {
    var offset = new Date().getTimezoneOffset();
    let tmp = new Date(date);
    let sport = this.props.match.params.sport || 'soccer';
    let dateString = tmp.getFullYear() + '-' + (tmp.getMonth() + 1) + '-' + tmp.getDate();
    this.props.getMatches(sport, dateString, offset, function() {
      this.setState({date: dateString})
    }.bind(this));
  }

  closeMatchDetails = () => {
    this.setState({showMobileMatchDetails: false});
    document.body.classList.toggle('mobile-matchDetails', false)
  }

  selectMatch = (matchId) => {
    const { currentSport, date } = this.state;
    document.body.classList.toggle('mobile-matchDetails', true);
    this.props.getMatch(matchId);
    this.props.getMatchInfo(matchId);

    let selectedMatch = null;

    selectedMatch = _.find(this.props.matches.matches, function(m) {
      return parseInt(m.id, 10) === parseInt(matchId, 10);
    });

    let title = helpers.toTitleCase(currentSport.replace('-', ' ')) + ' / ' +
                helpers.toTitleCase(selectedMatch.home_team.name) +
                ' vs ' +
                helpers.toTitleCase(selectedMatch.visiting_team.name) +
                ' / ' + date;

    this.setState({
      selectedMatch: selectedMatch,
      showMobileMatchDetails: true,
      title: title
    });
  }
 

  render() {
    const { date, currentSport, matchesLoaded, selectedMatch, showMobileMatchDetails, title } = this.state;
    let mobileActive = this.props.showMobileMatchDetails === true ? 'no-scroll' : '';

  

    return (
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title + ' - ' + siteName}</title>
          <meta property="og:title" content={title + ' - ' + siteName} />
          <meta property="og:description" content={i18n.t('metas.description')} />
          <meta property="og:image" content="" />
          <meta property="og:url" content={base + this.props.location.pathname} />
          <meta name="twitter:title" content={title + ' - ' + siteName} />
          <meta name="twitter:description" content={i18n.t('metas.description')} />
          <meta name="twitter:image" content="" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="apple-itunes-app" content="app-id=000000" />
        </Helmet>

        <header className="site-header">
          <div className="close" onClick={this.closeMatchDetails}>
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
              <path fill="#fff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
          </div>
          <div className="site-logo">
            <a href="/"><img src="/logo.png" alt={i18n.t('metas.title')}/></a>
          </div>
          <a className="burger burger-3" onClick={burgerToggle}>
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
            </a>
          <div className="sports-menu">
            <Menu date={date} selectedSport={currentSport} />
          </div>
          { /* <SwitchLanguage/> */ }
        </header>
        <MobileView viewClassName="appLink">
        <div className="appLink-active">
        <div className="text">
          Download the new {siteName} app <a href="#">iPhone </a>
          or <a href='#'>Android</a>
          </div>
          <div className="close">
          <span onClick={removeBanner}>close</span>
          </div>
        </div>
        </MobileView>
  



        <Matches
          className={mobileActive}
          currentSport={currentSport}
          date={date}
          matches={this.props.matches}
          selectedMatch={selectedMatch}
          selectedMatchDetails={this.props.matchDetails}
          matchInfo={this.props.matchInfo}
          matchesLoaded={matchesLoaded}
          onChangeDate={this.onChangeDate}
          selectMatch={this.selectMatch}
          showMobileMatchDetails={showMobileMatchDetails} />


        <Footer/>

      </div>

      

    );
  }

}



App.propTypes = {
  matches: PropTypes.object.isRequired,
  matchDetails: PropTypes.object.isRequired,
  //matchInfo: PropTypes.object.isRequired
}

function removeBanner() {
  let banner = document.querySelector('.appLink');
  banner.classList.toggle("remove");
}

function burgerToggle() {
  let sportMenu = document.querySelector('.sports-menu');
  let burger = document.querySelector('.burger');
  sportMenu.classList.toggle("mobile");
  burger.classList.toggle("active");
}

function mapStateToProps(state) {
  return {
    matches: state.matches,
    matchDetails: state.match,
    matchInfo: state.matchInfo
  }
}

/*function mapDispatchToProps(dispatch) { 
  return {
    mappedUpdateMinutes: minutes => dispatch(updateMinutes(minutes))
  }
}*/



export default connect(mapStateToProps, { getMatches, getMatch, updateMinutes, updateMatches, getMatchInfo })(App);


 