import React, { Component } from 'react';
import _ from 'lodash';

export default class GameTracker extends Component {
	renderQuarter = (events) => {
		let rows = events.map(event => {
			let score = event.home_team_score - event.visiting_team_score;
			let homeAway = score > 0 ? 'home' : 'away';

			return (
				<div className={"bar-" + Math.abs(score) + ' ' + homeAway} key={event.id}>
					<div className="digit"></div>
				</div>
			)
		});

		return (
			<div className="quarter">
				{rows}
			</div>
		)
	}

	render() {
		let match = this.props.match;
		let matchEvents = this.props.matchEvents;
		let q1 = [];
		let q2 = [];
		let q3 = [];
		let q4 = [];

		// NBA
		if(match.tournament.unique_tournament_id === 132) {
			q1 = _.filter(matchEvents, function(e) {return e.minute <= 12;});
			q2 = _.filter(matchEvents, function(e) {return e.minute >= 13 && e.minute <= 24;});
			q3 = _.filter(matchEvents, function(e) {return e.minute >= 25 && e.minute <= 36;});
			q4 = _.filter(matchEvents, function(e) {return e.minute > 36;});
		} else {
			q1 = _.filter(matchEvents, function(e) {return e.minute <= 10;});
			q2 = _.filter(matchEvents, function(e) {return e.minute >= 11 && e.minute <= 20;});
			q3 = _.filter(matchEvents, function(e) {return e.minute >= 21 && e.minute <= 30;});
			q4 = _.filter(matchEvents, function(e) {return e.minute > 30;});
		}

		return (
			<div className="component">
				<div className="basket-timeline">
					{this.renderQuarter(q1)}
					{this.renderQuarter(q2)}
					{this.renderQuarter(q3)}
					{this.renderQuarter(q4)}
				</div>
				<div className="labels">
			   <div className="label">Q1</div>
			   <div className="label">Q2</div>
			   <div className="label">Q3</div>
			   <div className="label">Q4</div>
			 </div>
			</div>
		)
	}
}

 