import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateMatch } from '../actions/actions_match';
import Match from '../components/match';
import MatchDetails from '../components/match_details';
import MatchTournament from '../components/match_tournament';
import CategoryList from './category_list';
import DatePicker from '../components/datepicker';
import { AdTop, AdSidebarLeft, AdSidebarRight } from '../components/adserver/dfp_ads';
 
//import LeagueTables from './league_table';  

//import ReactLoading from 'react-loading';


class Matches extends Component {
  selectMatch = (match) => {
    this.props.selectMatch(match.id);
  }

  removeHasGoal = (match, callback) => {
    this.props.updateMatch(match);
    callback();
  }

  renderMatches = () => {
    let matches = [];
    var lastCount = 0;
    let tournaments = this.props.matches.tournaments || [];
    let allMatches = this.props.matches.matches || [];
    console.log(this.props.sport)
    tournaments.forEach(function(tournament, index) {
      let count = tournament.count;
      let favorite = '';

      let rows = allMatches.slice(lastCount, lastCount+count).map(function(match, index) {
        return (
              <Match
                key={'a'+match.id}
                match={match}
                removeHasGoal={this.removeHasGoal}
                selectMatch={this.selectMatch}
                selectedMatch={this.props.selectedMatch} />
          );
      }.bind(this));

      matches[index] = <MatchTournament key={tournament.tournament.id} tournament={tournament.tournament} favorite={favorite} matches={rows} />;

      lastCount += count;
    }.bind(this));

    return matches;
  }

  render() {
    const { selectedMatch } = this.props;
    let tournaments = this.props.matches.tournaments || [];
    let mobileActive = this.props.showMobileMatchDetails === true ? 'mobile-active' : '';
 

    return (
      <div className="page">
         
      
        <aside className="sidebar-left">
       
        <CategoryList tournaments={tournaments} />
       
     

            <AdSidebarLeft/>
         
  
    

          
  
      
         
          
 
        </aside>
     
  

        <div className="main">

       

          <DatePicker date={this.props.date} currentSport={this.props.currentSport} onChangeDate={this.props.onChangeDate} />
          <div id='events'>
          {
            this.props.matchesLoaded === true ? 
              tournaments.length > 0 ?
                this.renderMatches() : 
                <div className="no-matches">No matches on selected date</div> :
              <div className="no-matches ">
                
                <div className="ui active centered inline loader"></div>

               
            
              </div>
          }
          </div>
        </div>
        <aside id="sidebar" className={'sidebar-right ' + mobileActive}>

          { /*
        <div className="ad_unit">
         <AdTop/>
        </div>
          */  }
      
        {
          selectedMatch !== null ?
            <MatchDetails
              matchDetails={this.props.selectedMatchDetails}
              match={selectedMatch}
              matchInfo={this.props.matchInfo}
              showMobileMatchDetails={this.props.showMobileMatchDetails} /> :
            null
        }
      { /* <LeagueTables leagueId={1}/> */ }
          { /*<div id="favorites" className="sidebar-module">  
            <h3>Favorites</h3>
            <Favorites favorites={favoriteTournaments} />
          </div>*/}  
  
         <AdSidebarRight/>
    
         
 
 

		    </aside>
      </div>
    );
  }
}

export default connect(null, { updateMatch })(Matches);

