import axios from 'axios';

export const SET_SPORTS = 'SET_SPORTS';

export function setSports(sports) {
    return {
        type: SET_SPORTS,
        sports
    }
}

export function getSports(date, callback) {
    return dispatch => {
        return axios.get('https://data.scorecenter.live/sports', {
                params: {
                    date: date
                }
            })
            .then(res => {
                //callback();
                dispatch(setSports(res.data.sports));
            })
            .catch(err => {
             console.log(err);
            });
    }
}

  