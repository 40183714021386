import React, { Component } from 'react';
import { Header, Image, Button } from 'semantic-ui-react'
import '../../styles/custom_ads.css';


class Bet365C1 extends Component { 
  
  render() {
    return (
      
      <div className="bet365_c1">

        <a href="/links" target="_blank"> 
 
            <Header as='h4' textAlign='center' style={{margin:0}}>     
                <Image src={'/img/bet365-logo.svg'} size='tiny' verticalAlign='bottom' /> Bet on and watch Sports
                  <Button color='yellow' size='tiny' floated='right'>Register &amp; Watch</Button>
            </Header>
 
        </a>

      </div>

    );
  }
}
 


export { Bet365C1 }