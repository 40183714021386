import axios from 'axios';
import NProgress from 'nprogress';

export const SET_MATCHES = 'SET_MATCHES';
export const SET_MATCH = 'SET_MATCH';
export const SET_MATCHINFO = 'SET_MATCHINFO';
export const UPDATE_MINUTES = 'UPDATE_MINUTES';
export const UPDATE_MATCHES = 'UPDATE_MATCHES';
export const UPDATE_MATCH = 'UPDATE_MATCH';

export function setMatches(matches) {
    return {
        type: SET_MATCHES,
        matches
    }
}

export function setMatch(match) {
    return {
        type: SET_MATCH,
        match
    }
}

export function setMatchInfo(matchInfo) {
    return {
        type: SET_MATCHINFO,
        matchInfo
    }
}

export function updateMinutes(minutes) {
    return {
        type: UPDATE_MINUTES,
        minutes
    }
}

export function updateMatches(matches) {
    return {
        type: UPDATE_MATCHES,
        matches
    }
}

export function updateMatch(match) {
    return {
        type: UPDATE_MATCH,
        match
    }
}

export function getMatches(sport, date, offset, callback) {
    NProgress.start();
    return dispatch => {
        return axios.get('https://data.scorecenter.live/matches', {
                params: {
                    date: date,
                    sport: sport,
                    offset: offset
                }
            })
            .then(res => {
                if (callback) {
                    callback(res.data);
                }
                NProgress.done();
                 //console.log(res.data);
                dispatch(setMatches(res.data));
            })
            .catch(err => {
                //console.log(err);
                NProgress.done();
            });
    }
}

export function getMatch(matchId, callback) {
    return dispatch => {
        //data.scorecenter.live/matchDetails
        return axios.get('https://data.scorecenter.live/match_details', {   
                params: {
                    matchId: matchId
                }
            })
            .then(res => {
                if (callback) {  
                    callback();
                }
                //console.log(res.data);
                dispatch(setMatch(res.data));
            })
            .catch(err => {
                console.log(err);
            });
    }
}

export function getMatchInfo(matchId, callback) {
    return dispatch => {
        return axios.get('https://data.scorecenter.live/match_info', {   
                params: {
                    matchId: matchId
                }  
            })  
            .then(res => {
                if (callback) {  
                    callback();
                }
                //console.log(res.data.doc[0].data.match);
                dispatch(setMatchInfo(res.data.doc[0].data.match));
            })
            .catch(err => {
                console.log(err);
            });
    }
}

   