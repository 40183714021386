import React, { Component } from 'react';
export class TournamentsList extends Component {
    render() {
 
       
 
        return ( 
            <div className="module"> 
            <ul className="matchcomments">
             <li><a href="#">League</a></li>
             <li><a href="#">League</a></li>
             <li><a href="#">League</a></li>
            </ul>
        </div>
        
        );
    }
}

export default TournamentsList;