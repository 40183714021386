import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import common_en from '../src/translations/en.json'
import common_de from '../src/translations/de.json'

i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  // react i18next special options (optional)
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
  resources: {
    en: {
      translation: common_en,
      fallbackLng: 'en'
    },
    de: {
      translation: common_de,
      fallbackLng: 'de'
    },
  },
})  
export default i18n