import React, { Component } from 'react';
import '../styles/stream.css';
 

class Stream extends Component {
 
    render() {
     
 
 
      return (
        <div className="fmr_videoplayer-container" id="topClickListener">
        <a id="link1" className="fmr_link" href="https://links.sportlive.plus" target="_blank">  
       
            <div className="fmr_videoplayer">

                <div className="fmr_videoplayer__screen screen">
                <h3 className="screen__title"> Live Stream  </h3>
                    <div className="screen__play">
               
                        <div className="screen__playInner">
                        <i><svg version="1.1" id="icon-play-main" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve">
                                        <g>
                                            <path d="M2.219,14.864c-0.276,0.152-0.5,0.018-0.5-0.294V1.43c0-0.312,0.224-0.446,0.5-0.294l11.854,6.587
                                        		c0.276,0.152,0.276,0.402,0,0.554L2.219,14.864z"></path>
                                        </g>
                                    </svg></i>
                        </div>
                        <div className="screen__loader">
                        <i><svg version="1.1" id="icon-loader" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve"><g><path fill="#FFFFFF" d="M1.716,9.429c-0.634,0-1.143-0.51-1.143-1.143c0-0.634,0.509-1.143,1.143-1.143s1.143,0.509,1.143,1.143
                                        		C2.858,8.919,2.35,9.429,1.716,9.429z M3.555,5.269c-0.785,0-1.428-0.643-1.428-1.428c0-0.786,0.643-1.429,1.428-1.429
                                        		c0.786,0,1.429,0.643,1.429,1.429C4.983,4.626,4.341,5.269,3.555,5.269z M3.555,13.874c-0.625,0-1.143-0.518-1.143-1.143
                                        		c0-0.635,0.518-1.143,1.143-1.143c0.634,0,1.143,0.508,1.143,1.143C4.697,13.356,4.188,13.874,3.555,13.874z M8,3.716
                                        		c-0.946,0-1.714-0.768-1.714-1.714S7.054,0.288,8,0.288s1.714,0.768,1.714,1.714S8.946,3.716,8,3.716z M8,15.712
                                        		c-0.634,0-1.143-0.508-1.143-1.143c0-0.633,0.509-1.143,1.143-1.143s1.144,0.51,1.144,1.143C9.144,15.204,8.634,15.712,8,15.712z
                                        		 M12.446,5.84c-1.107,0-2-0.893-2-1.999c0-1.099,0.893-2,2-2c1.098,0,1.999,0.901,1.999,2C14.445,4.947,13.544,5.84,12.446,5.84z
                                        		 M12.446,13.874c-0.635,0-1.143-0.518-1.143-1.143c0-0.635,0.508-1.143,1.143-1.143c0.625,0,1.143,0.508,1.143,1.143
                                        		C13.589,13.356,13.071,13.874,12.446,13.874z M14.284,9.429c-0.633,0-1.143-0.51-1.143-1.143c0-0.634,0.51-1.143,1.143-1.143
                                        		c0.635,0,1.143,0.509,1.143,1.143C15.427,8.919,14.919,9.429,14.284,9.429z"></path></g>
                                    </svg>
                            </i>
                        </div>
                    </div>

                </div>

                <div className="fmr_videoplayer__ctrls ctrls">
                    <div className="ctrl ctrls__play">
                    <i><svg version="1.1" id="icon-play" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve">
                     <g><path d="M2.219,14.864c-0.276,0.152-0.5,0.018-0.5-0.294V1.43c0-0.312,0.224-0.446,0.5-0.294l11.854,6.587c0.276,0.152,0.276,0.402,0,0.554L2.219,14.864z"></path></g>
                    </svg></i>
                    </div>
                    <div className="ctrl ctrls__loader">
                        <div className="progress-loader">
                            <div className="progress-preload">
                            </div>
                            <div className="progress-full">
                            </div>
                        </div>
                    </div>
                    <div className="ctrl ctrls__volume">
                    <i>
                            <svg version="1.1" id="icon-volume" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve">
                                    <rect fill="none" width="16" height="16"></rect>
                                <g>
                                    <path d="M7.429,12.856c0,0.312-0.259,0.571-0.571,0.571c-0.151,0-0.295-0.062-0.401-0.169l-2.973-2.973H1.145
                                    		c-0.312,0-0.571-0.259-0.571-0.571V6.286c0-0.312,0.259-0.571,0.571-0.571h2.339l2.973-2.973c0.106-0.107,0.25-0.169,0.401-0.169
                                    		c0.312,0,0.571,0.259,0.571,0.571V12.856z M9.473,10.098c-0.071,0.036-0.151,0.044-0.223,0.044c-0.312,0-0.571-0.25-0.571-0.571
                                    		C8.679,8.893,9.714,9.08,9.714,8S8.679,7.107,8.679,6.429c0-0.322,0.259-0.571,0.571-0.571c0.071,0,0.151,0.009,0.223,0.044
                                    		c0.839,0.33,1.384,1.205,1.384,2.098S10.312,9.758,9.473,10.098z M10.374,12.204c-0.071,0.027-0.151,0.045-0.223,0.045
                                    		c-0.321,0-0.58-0.259-0.58-0.571c0-0.25,0.143-0.419,0.348-0.527c0.241-0.125,0.465-0.232,0.679-0.393
                                    		c0.884-0.643,1.401-1.669,1.401-2.758s-0.518-2.116-1.401-2.758c-0.214-0.161-0.438-0.268-0.679-0.393
                                    		C9.714,4.742,9.571,4.572,9.571,4.322c0-0.312,0.259-0.571,0.571-0.571c0.08,0,0.16,0.018,0.231,0.045
                                    		C12.053,4.501,13.142,6.188,13.142,8S12.053,11.499,10.374,12.204z M11.276,14.302c-0.072,0.026-0.152,0.044-0.232,0.044
                                    		c-0.312,0-0.571-0.259-0.571-0.571c0-0.259,0.134-0.402,0.348-0.527c0.125-0.071,0.269-0.116,0.402-0.188
                                    		c0.25-0.134,0.5-0.286,0.731-0.455c1.464-1.08,2.33-2.785,2.33-4.606s-0.866-3.526-2.33-4.606
                                    		c-0.231-0.169-0.481-0.321-0.731-0.455c-0.134-0.072-0.277-0.116-0.402-0.188c-0.214-0.125-0.348-0.268-0.348-0.527
                                    		c0-0.312,0.259-0.571,0.571-0.571c0.08,0,0.16,0.018,0.232,0.044c2.517,1.08,4.15,3.562,4.15,6.302S13.793,13.222,11.276,14.302z"></path>
                                </g>
                                </svg>
                        </i>
                    </div>
                    <div className="ctrl ctrls__fullscreen">
                    <i>
                            <svg version="1.1" id="icon-fullscreen" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enableBackground="new 0 0 16 16" xmlSpace="preserve">
                                    <g>
                                        <path d="M7.795,9.633l-2.964,2.964l1.285,1.285c0.107,0.107,0.17,0.25,0.17,0.401c0,0.312-0.259,0.572-0.571,0.572H1.716
                                    		c-0.312,0-0.571-0.26-0.571-0.572v-3.998c0-0.312,0.259-0.572,0.571-0.572c0.151,0,0.295,0.062,0.401,0.17l1.286,1.285l2.963-2.963
                                    		C6.42,8.151,6.5,8.116,6.572,8.116c0.071,0,0.151,0.035,0.205,0.089l1.018,1.018c0.054,0.054,0.089,0.134,0.089,0.205
                                    		S7.849,9.58,7.795,9.633z M14.855,5.715c0,0.312-0.259,0.571-0.571,0.571c-0.151,0-0.295-0.062-0.401-0.17l-1.286-1.285
                                    		L9.634,7.795C9.58,7.848,9.5,7.884,9.428,7.884c-0.071,0-0.151-0.036-0.205-0.089L8.205,6.776C8.151,6.724,8.116,6.643,8.116,6.571
                                    		S8.151,6.42,8.205,6.366l2.964-2.964L9.884,2.117c-0.107-0.107-0.17-0.25-0.17-0.401c0-0.312,0.259-0.571,0.571-0.571h3.999
                                    		c0.312,0,0.571,0.259,0.571,0.571V5.715z"></path>
                                    </g>
                                <rect fill="none" width="16" height="16"></rect>
                                </svg>
                        </i>
                    </div>
                </div>
   
            </div>
        </a>

 

    </div>

      );
    }
  }

export default Stream;