import axios from 'axios';

export const SET_LEAGUE_TABLES = 'SET_LEAGUE_TABLES';

export function setLeagueTables(leagueTables) {
    return {
        type: SET_LEAGUE_TABLES,
        leagueTables
    }
}

export function getLeagueTables(leagueId, callback) {
    return dispatch => {
        return axios.get('https://data.scorecenter.live/leagueTables', {
                params: {
                    leagueId: leagueId
                }
            })
            .then(res => {
                //callback();
                dispatch(setLeagueTables(res.data));
            })
            .catch(err => {
                console.log(err); 
            });
    }  
}

export function getLeague(leagueId, callback) {
    return dispatch => {
        return axios.get('https://data.scorecenter.live/league', {
                params: {
                    leagueId: leagueId
                }
            })
            .then(res => {
                callback(res.data.league, res.data.matches);
                //dispatch(setLeagueTables(res.data));
            })
            .catch(err => {
                console.log(err);
            });
    }
}


