import React, { Component } from 'react';
 

class Footer extends Component { 
  
  render() {
    return (
        <footer className="site-footer">
   
 
        <a href="https://www.begambleaware.org/" target="_blank" rel="nofollow"><img src={'/img/GambleAware.png'} /> <span className="css-c19m5y social-item age">18+</span></a>
     
     
        <div className="footer-copyright"><a href="/">SportLive</a></div>
        <div className="footer-bottom">{new Date().getFullYear()} </div>
     
       
        </footer>
    );
  }
}
 
export default Footer