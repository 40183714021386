import React, { Component } from 'react';
import * as helpers from '../utils/helpers';

class MatchEvent extends Component {
  getPenalty = (event) => {
  	if(event.from_penalty === true) {
      return ' (Pen)';
    }

    return '';
  }

  getOwnGoal = (event) => {
  	if(event.own_goal === true) {
      return ' (OG)';
    }

    return '';
  }

  getAssists = (event) => {
  	let assists = '';
  	if(event.assists && event.assists.length > 0) {
      assists = ' assist:';
      event.assists.forEach(function(assist) {
        assists += ' ' + assist.player.name + ' +';
      });
      assists = <span className="assist">{assists.slice(0, -1)}</span>;
    }

    return assists;
  }

  getPlayerName = (event) => {
  	let playerName = helpers.getSafe(() => event.player.name);

  	if(event.type === 'GOAL' && playerName === '') {
  		playerName = event.home_team_score + ' - ' + event.visiting_team_score;
  	} else if(event.type === 'GOAL' && (this.props.sport.toLowerCase() === 'rugby' || this.props.sport.toLowerCase() === 'handball')) {
  		if(this.props.type === 'home') {
  			playerName = '( ' + event.home_team_score + ' - ' + event.visiting_team_score + ' ) ' + playerName;
  		} else {
  			playerName = playerName + ' ( ' + event.home_team_score + ' - ' + event.visiting_team_score + ' )';
  		}

  	}

  	return playerName;
  }

  getInPlayerName = (event) => {
  	let playerName = '';

		if(event.type === 'SUBSTITUTION') {
			playerName = helpers.getSafe(() => event.in_player.name);
  	}

  	return playerName;
  }

  getOutPlayerName = (event) => {
  	let playerName = '';

		if(event.type === 'SUBSTITUTION') {
			playerName = helpers.getSafe(() => event.out_player.name);
  	}

  	return playerName;
  }

	render() {
		let event = this.props.matchEvent;
		let type = this.props.type;
    let sport = this.props.sport.replace(/\s+/g, '-').toLowerCase();

		let playerName = this.getPlayerName(event);
    let inPlayerName = (this.getInPlayerName(event) !== '') ? <div className="sub-in">{this.getInPlayerName(event) + ' (in)'}</div> : '';
    let outPlayerName = (this.getOutPlayerName(event) !== '') ? <div className="sub-out">{this.getOutPlayerName(event) + ' (out)'}</div> : '';
		// let inPlayerName = this.getInPlayerName(event) + ' (in)';
		// let outPlayerName = this.getOutPlayerName(event) + ' (out)';
    let penalty = this.getPenalty(event);
    let ownGoal = this.getOwnGoal(event);
		let assists = this.getAssists(event);

		return (
			<div className="event">
				{
					type === 'away' ?
						<div className={event.type + ' ' + sport} /> :
						null
				}
        <div className="player">
          {playerName + penalty + ownGoal}
          {inPlayerName}
          {outPlayerName}
          {assists}
        </div>
        {
        	type === 'home' ?
        		<div className={event.type + ' ' + sport} /> :
        		null
        }
      </div>
		)
	}
}

export default MatchEvent;

 