import React, {
    Component
} from 'react';
import {
    Link
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';

const re = new RegExp(' ', 'g');

class Match extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasGoal: false
        };
    }

    componentWillReceiveProps(nextProps) {
        var self = this;
        if (nextProps.match.goal_scored && nextProps.match.goal_scored === true) {
            this.setState({
                hasGoal: true
            }, function() {
                setTimeout(function() {
                    self.props.removeHasGoal(self.props.match, function() {
                        self.setState({
                            hasGoal: false
                        });
                    });
                }, 15000);
            });
        }
    }

    selectMatch = () => {
        let match = this.props.match;
        this.props.selectMatch(this.props.match);
        // console.log(match.tournament.category.sport.name);
        // console.log(match.home_team.name + ' - ' + match.visiting_team.name);
        const prod = process.env.NODE_ENV === 'production';
        if (prod) {
            // console.log(match.tournament.category.sport.name);
            ReactGA.event({
                category: match.tournament.category.sport.name + ' Match',
                action: 'Click Match',
                label: match.home_team.name + match.visiting_team.name
            });
        }
    }

    getMatchMinute = () => {
        let match = this.props.match;
        let has_sets = match.tournament.category.sport.has_sets || false;
        if (match.status === 'FINISHED') {
            if (match.finished_time_status === 'PENALTY_SHOOTOUT') {
                return ( 
                    <span>
                    <span data-tip = 'After Penalties' > AP </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            } else if (match.finished_time_status === 'Abandoned') {
                return ( <span>
                    <span data-tip = 'Abandoned' > Aband. </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            } else if (match.finished_time_status === 'Retired') {
                return ( 
                    <span>
                    <span data-tip = 'Retired' > Ret. </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            } else if (match.finished_time_status === 'OVERTIME') {
                return ( 
                    <span>
                    <span data-tip = 'Overtime' > OT </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }

            return 'FT';
        } else if (match.status === 'POSTPONED') {
            return (
                <span>
                <span data-tip = 'Postponed' > PPD </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        } else if (match.status === 'UPCOMING') {
            let matchDate = new Date(match.match_date);
            return ('0' + matchDate.getHours()).slice(-2) + ':' + ('0' + matchDate.getMinutes()).slice(-2);
        } else if (match.status === 'Canceled') {
            return ( 
                <span>
                <span data-tip = 'Canceled' > CX </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        } else if (match.status === 'ONGOING' && has_sets) {
            let type = 'p';
            let typeString = 'period';
            let sport = match.tournament.category.sport.name;
            if (sport === 'Tennis') {
                type = 's';
                typeString = 'set';
            } else if (sport === 'Basketball') {
                type = 'q';
                typeString = 'quarter';
            }

            if (match.current_period_type === 'Pause') {
                return 'Pause';
            }
            if (match.current_period === '1') {
                return ( 
                    <span>
                    <span data-tip = {
                        '1st ' + typeString
                    } > {
                        '1' + type
                    } </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }
            if (match.current_period === '2') {
                return ( 
                <span>
                    <span data-tip = {
                        '2nd ' + typeString
                    } > {
                        '2' + type
                    } </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }
            if (match.current_period === '3') {
                return ( 
                    <span>
                    <span data-tip = {
                        '3rd ' + typeString
                    } > {
                        '3' + type
                    } </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }
            if (match.current_period === '4') {
                return ( 
                    <span>
                    <span data-tip = {
                        '4th ' + typeString
                    } > {
                        '4' + type
                    } </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }
            if (match.current_period_type === 'Overtime') {
                return ( 
                    <span>
                    <span data-tip = 'Overtime' > O </span> 
                    <ReactTooltip place = "left"
                    type = "info"
                    effect = "solid"/>
                    </span>
                )
            }
        }

        return match.minute;
    }

    renderSetScores = (scores) => {
        return scores.map(score => {
            if (score.type === 'Period1' ||
                score.type === 'Period2' ||
                score.type === 'Period3' ||
                score.type === 'Period4' ||
                score.type === 'Overtime' ||
                score.type === 'Penalties') {
                return ( 
                    <div key = {
                        score.id
                    }
                    className = "set" >
                    <div className = "home-score" > {
                        score.team1
                    } </div> 
                    <div className = "away-score" > {
                        score.team2
                    } </div> 
                    </div>
                )
            } else {
                return null;
            }
        });
    }

    render() {
        const {
            hasGoal
        } = this.state;
        let match = this.props.match;
        let selectedMatch = this.props.selectedMatch;
        //var startDate = new Date(match.match_date);
        //var sportName = match.tournament.category.sport.name;

        let has_sets = match.tournament.category.sport.has_sets || false;

        let homeScore = match.home_team_score === null ? '' : match.home_team_score;
        let awayScore = match.visiting_team_score === null ? '' : match.visiting_team_score;
        if (match.status === 'UPCOMING' ||
            match.status === 'Canceled' ||
            match.status === 'POSTPONED' ||
            match.finished_time_status === 'Abandoned') {
            homeScore = '-';
            awayScore = '-';
        } else if (match.finished_time_status === 'PENALTY_SHOOTOUT') {
            match.scores.forEach(function(score) {
                if (score.type === 'AP') {
                    homeScore = score.team1;
                    awayScore = score.team2;
                }
            });
        }

        let draw = match.winner === 3 ? ' draw' : '';
        let selectedMatchId = selectedMatch === null ? null : selectedMatch.id;
        //let liveClass = match.status === 'ONGOING' ? ' live' : '';
        let activeClass = match.id === selectedMatchId ? ' active' : '';
        let goalClass = hasGoal ? 'goal' : '';
        let sportName = match.tournament.category.sport.name.toLowerCase().replace(re, '-');
        let teamNames = match.home_team.name.replace(re, '-') + '-vs-' + match.visiting_team.name.replace(re, '-');

        let matchDate = new Date(match.match_date);
        let dateString = matchDate.getFullYear() + '-' + (matchDate.getMonth() + 1) + '-' + matchDate.getDate();

        return ( 
            <li className = {
                "matchRow" + activeClass + ' ' + goalClass + ' ' + match.status.toLowerCase()
            }
            onClick = {
                this.selectMatch
            } >
            <Link to = {
                '/' + sportName + '/' + teamNames.toLowerCase() + '/' + match.id
            } >
            <div className = "status" > {
                this.getMatchMinute()
            } </div> 
            <div className = "teams" >
            <div className = {
                match.winner === 1 ? 'home-team winner' : 'home-team' + draw
            } > {
                match.home_team.name
            } </div> 
            <div className = {
                match.winner === 2 ? 'away-team winner' : 'away-team' + draw
            } > {
                match.visiting_team.name
            } </div></div> 
            <div className = "score" > {
                has_sets === true ?
                this.renderSetScores(match.scores) : null
            } <div className = "total" >
            <div className = "home-score" > {
                homeScore
            } </div> 
            <div className = "away-score" > {
                awayScore
            } </div> </div> </div> </Link> </li>
        );
    }
}

export default Match;
 
