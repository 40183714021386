import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import MatchEvents from './match_events';
import Lineups from './lineups';
import Stats from './stats';
import MatchComments from './match_comments';
//import MatchFunfacts from './match_funfacts';
import * as helpers from '../utils/helpers';
import ReactTooltip from 'react-tooltip';
import ReactGA from 'react-ga';
import Stream from './stream';   
import { Bet365C1 } from './adserver/custom_ads';   
import i18n from '../i18n';

class MatchDetails extends Component {
  constructor(props) { 
    super(props);

    this.state = { tab: 'stream' };
  }

  getMatchMinute = () => {
    let match = this.props.match;
    let has_sets = match.tournament.category.sport.has_sets || false;
    if (match.status === 'FINISHED') {
        if (match.finished_time_status === 'PENALTY_SHOOTOUT') {
            return ( 
                <span>
                <span data-tip = {i18n.t('match.after_penalties')} > {i18n.t('match.a_p')} </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        } else if (match.finished_time_status === 'Abandoned') {
            return ( <span>
                <span data-tip = {i18n.t('match.abandoned')} > {i18n.t('match.aband')} </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        } else if (match.finished_time_status === 'Retired') {
            return ( 
                <span>
                <span data-tip = {i18n.t('match.ret')} > {i18n.t('match.retired')} </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        } else if (match.finished_time_status === 'OVERTIME') {
            return ( 
                <span>
                <span data-tip = {i18n.t('match.overtime')} > {i18n.t('match.overtime')} </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }

        return 'FT';
    } else if (match.status === 'POSTPONED') {
        return (
            <span>
            <span data-tip = {i18n.t('match.post')} > {i18n.t('match.postponed')} </span> 
            <ReactTooltip place = "left"
            type = "info"
            effect = "solid"/>
            </span>
        )
    } else if (match.status === 'UPCOMING') {
        let matchDate = new Date(match.match_date);
        //return ('0' + matchDate.getHours()).slice(-2) + ':' + ('0' + matchDate.getMinutes()).slice(-2);
    } else if (match.status === 'Canceled') {
        return ( 
            <span>
            <span data-tip = {i18n.t('match.can')} > {i18n.t('match.canceled')} </span> 
            <ReactTooltip place = "left"
            type = "info"
            effect = "solid"/>
            </span>
        )
    } else if (match.status === 'ONGOING' && has_sets) {
        let type = 'p';
        let typeString = 'period';
        let sport = match.tournament.category.sport.name;
        if (sport === 'Tennis') {
            type = 's';
            typeString = 'set';
        } else if (sport === 'Basketball') {
            type = 'q';
            typeString = 'quarter';
        }

        if (match.current_period_type === 'Pause') {
            return 'Pause';
        }
        if (match.current_period === '1') {
            return ( 
                <span>
                <span data-tip = {
                    '1st ' + typeString
                } > {
                    '1' + type
                } </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }
        if (match.current_period === '2') {
            return ( 
            <span>
                <span data-tip = {
                    '2nd ' + typeString
                } > {
                    '2' + type
                } </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }
        if (match.current_period === '3') {
            return ( 
                <span>
                <span data-tip = {
                    '3rd ' + typeString
                } > {
                    '3' + type
                } </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }
        if (match.current_period === '4') {
            return ( 
                <span>
                <span data-tip = {
                    '4th ' + typeString
                } > {
                    '4' + type
                } </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }
        if (match.current_period_type === 'Overtime') {
            return ( 
                <span>
                <span data-tip = 'Overtime' > {i18n.t('match.overtime')} </span> 
                <ReactTooltip place = "left"
                type = "info"
                effect = "solid"/>
                </span>
            )
        }
 
    }

    return  match.minute 
       
}

  componentWillReceiveProps(nextProps) {
    let matchDetails = nextProps.matchDetails;
    let tab = this.state.tab;
    if (tab === '') {
 
      if ((matchDetails.goals !== undefined && matchDetails.goals.length > 0) ||
       (matchDetails.cards !== undefined && matchDetails.cards.length > 0) ||
       (matchDetails.substitutions !== undefined && matchDetails.substitutions.length > 0)) {
        tab = 'events';
      } else if(matchDetails.lineups !== undefined && matchDetails.lineups.length > 0) {
        tab = 'lineups';
      } else if(matchDetails.statistics !== undefined && matchDetails.statistics.length > 0) {
        tab = 'stats';
      } else if(matchDetails.matchComments !== undefined && matchDetails.matchComments.length > 0) {
        tab = 'Commentary';
      }
    }

    this.setState({tab: tab});
  }

  onTabClick = (tab) => {
    this.setState({tab: tab});
    const prod = process.env.NODE_ENV === 'production';
    if (prod) {
      ReactGA.event({
        category: 'Match Tab',
        action: tab
      });
    }
  }

  renderTabs = () => {
    const { tab } = this.state;

    //let match = this.props.match.selectedMatch;
    let matchDetails = this.props.matchDetails;

    let stream = null;
    let events = null;
    let lineups = null;
    let stats = null;
    let matchcomments = null;


      stream = <div className={'tab ' + (tab === 'stream' ? 'active' : '')} onClick={this.onTabClick.bind(this, 'stream')}>
                {i18n.t('tabs.stream')}
              </div>;

    if((matchDetails.goals !== undefined && matchDetails.goals.length > 0) ||
       (matchDetails.cards !== undefined && matchDetails.cards.length > 0) ||
       (matchDetails.substitutions !== undefined && matchDetails.substitutions.length > 0)) {
      events = <div className={'tab ' + (tab === 'events' ? 'active' : '')} onClick={this.onTabClick.bind(this, 'events')}>
                 {i18n.t('tabs.events')}
        </div>;
    }

    if(matchDetails.lineups !== undefined && matchDetails.lineups.length > 0) {
      lineups = <div className={'tab ' + (tab === 'lineups' ? 'active' : '')} onClick={this.onTabClick.bind(this, 'lineups')}>
                 {i18n.t('tabs.lineups')}
                </div>;
    }
    if(matchDetails.statistics !== undefined && matchDetails.statistics.length > 0) {
      stats = <div className={'tab ' + (tab === 'stats' ? 'active' : '')} onClick={this.onTabClick.bind(this, 'stats')}>
                 {i18n.t('tabs.stats')}
              </div>;  
    }

    if(matchDetails.matchComments !== undefined && matchDetails.matchComments.length > 0) {
      matchcomments = <div className={'tab ' + (tab === 'matchcomments' ? 'active' : '')} onClick={this.onTabClick.bind(this, 'matchcomments')}>
                 {i18n.t('tabs.commentary')}
              </div>;
    }

    return (
      <div className="tabs">
        {stream}
        {events}
        {lineups}
        {stats}
        {matchcomments}
      </div>
    )
  }

  renderAggregateScore = () => {
    let match = this.props.match;
    let aggregateScore = null;
    match.scores.forEach(function(score) {
      if (score.type === 'Aggregated') {
        aggregateScore = <div className="aggregateScore">{i18n.t('match.aggregated_score') + ' ' + score.team1 + ' - ' + score.team2}</div>;
      }
    });

    return aggregateScore;
  }
/*
  getMatchRound = () => { 
    let matchInfo = this.props.matchInfo;
    let round = matchInfo.round;
    //console.log(matchInfo);
    return ( 
        <span> Round: {round} </span>
    )

}

*/

  render() {
    const { tab } = this.state;

    let match = this.props.match;
    let matchInfo = this.props.matchInfo;
    let round = matchInfo.round;
    let matchDetails = this.props.matchDetails;

    let categoryName = match.tournament.category.name;
    let tournamentName = match.tournament.name;
    let homeTeamName = helpers.getSafe(() => match.home_team.name);
    let homeTeamScore = helpers.getSafe(() => match.home_team_score);
    let awayTeamName = helpers.getSafe(() => match.visiting_team.name);
    let awayTeamScore = helpers.getSafe(() => match.visiting_team_score);

    let refereeName = helpers.getSafe(() => i18n.t('match.referee') + ' ' + match.referee.name);
    let venueName = helpers.getSafe(() => match.venue.name + ', ');
    let venueLocation = helpers.getSafe(() => match.venue.city + ', ') + helpers.getSafe(() => match.venue.country.name);
    if (matchInfo.status){
    var status = matchInfo.status.name;
    }
    return (
      <div id="match-events" className="sidebar-module">
        
        <Bet365C1 key={homeTeamName}/>

        <header>

        <div className="league-info"><span className={"country flag " + categoryName.toLowerCase()}></span>{categoryName + ' - ' + tournamentName}  <div className="round-info">  - {i18n.t('match.round')} {round} </div></div>
        
        <h1 className="module-title">{homeTeamName} vs {awayTeamName} {i18n.t('match.header')}</h1>

            
          <div className="teams-wrap">
            <div className="team home">
              <div className="score">{homeTeamScore}</div>  
              <div className="name"><h2>{homeTeamName}</h2></div>
              <div className="line" />
            </div>

            <div className = {
                match.status.toLowerCase() + " matchDetails"
            }>
           { /* <div className="status"> {match.status} </div> */ }
            <div className="time"> {
                this.getMatchMinute() 

             
               
            }   </div>
            
            <div className={'status ' + match.status}> {status}</div> 

            <div className="hour"> {
               <Moment format="HH:mm">{match.match_date}</Moment>  
            } </div>


            <div className="date"> {
               <Moment format="YYYY/MM/DD">{match.match_date}</Moment>  
            } </div>
           
         
            </div>
  
            <div className="team away">
              <div className="score">{awayTeamScore}</div>
              <div className="name"><h2>{awayTeamName}</h2></div>
              <div className="line" />
            </div>
          </div>
          {this.renderAggregateScore()}
          <div className="match-info">
            <div className="stadium-info">{venueName} {venueLocation}</div>
            <div className="referee-info">{refereeName}</div>
          </div>

          { /* 
          <div className="btn">
        
          <Link to={window.location.pathname + "/details"}>ddfdfffes</Link>
          </div>
          */ }


        </header>
        {this.renderTabs()}  
        {
          tab === 'stream' ?
            <Stream match={match} matchDetails={matchDetails}/> :
          tab === 'events' ?
            <MatchEvents match={match} matchDetails={matchDetails} /> :
          tab === 'lineups' ?
            <Lineups match={match} matchDetails={matchDetails} /> :
          tab === 'stats' ?
            <Stats match={match} matchDetails={matchDetails} /> :
          tab === 'matchcomments' ?
            <MatchComments match={match} matchDetails={matchDetails} /> :
            null
        }

{ /* <MatchFunfacts/> */ } 

      </div>


    );
 
  }
}

export default MatchDetails;


 