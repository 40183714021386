import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategories } from '../actions/actions_categories';
import _ from 'lodash';
import i18n from '../i18n'
var re = new RegExp(' ', 'g');

class CategoryList extends Component {
  renderCategories = () => {
    let categories = [];

    this.props.tournaments.forEach(function(tournament) {
      let country = tournament.tournament.category.name;
     
      if(_.some(categories, {category: country})) {
        categories.forEach(function(category) {
          if(category.category === country) {
            category.count += tournament.count;
          }
        });
      } else {
        categories.push({category: country, count: tournament.count});
      }
    });

    return categories.map(function(category) {

      let tournamentLinkName = category.category.replace(re, '-').replace(',', '').toLowerCase();
      
      return (
        <li key={category.category}><a href={"#" + category.category}>{category.category} <span className="count">{category.count}</span></a></li>
      )
    });
  }

	render() {
		return (
			<div className="module">
        <h3 className="module-title">{i18n.t('user.leagues')}</h3>
        <ul>
        	{this.renderCategories()}
        </ul>
      </div>
		)
	}
}

CategoryList.propTypes = {
  categories: PropTypes.array.isRequired
}

function mapStateToProps(state) {
  return {
    categories: state.categories
  }
}

export default connect(mapStateToProps, { getCategories })(CategoryList);

 