import React, { Component } from 'react';
import { DFPSlotsProvider, AdSlot } from 'react-dfp';

class AdTop extends Component { 
  
  render() {
    return (
      <DFPSlotsProvider dfpNetworkId="72040985"> 
        <div className="desktop-ads ad_unit">
          <AdSlot slotId="div-gpt-ad-1569965823295-0" sizes={[[468, 60]]} adUnit="ad_top" />
        </div>
       <div className="mobile-ads">
          <AdSlot slotId="div-gpt-ad-1569965823295-0" sizes={[[300, 250]]} adUnit="ad_top" />
       </div> 
    </DFPSlotsProvider>   
    );
  }
}

class SyTop extends Component { 
  
  render() {
    return (
      <DFPSlotsProvider dfpNetworkId="72040985"> 
        <div className="desktop-ads ad_unit">
          <AdSlot slotId="div-gpt-ad-1569964085973-0" sizes={[[728, 90],[970, 90],[980, 120]]} adUnit="sy_top" />
        </div>
        { /*
        <div className="mobile-ads">
          <AdSlot slotId="div-gpt-ad-1570176190877-0" sizes={[[300, 250]]} adUnit="sy_top_mobile" />
       </div> 
        */ }
    </DFPSlotsProvider>   
    );
  }
}


class AdSidebarLeft extends Component { 
  
  render() {
    return (
      <DFPSlotsProvider dfpNetworkId="72040985">
        <div id="ad_left" className="desktop-ads ad_unit">
          <AdSlot slotId="div-gpt-ad-1569956714696-0" sizes={[[160, 600]]} adUnit="sy_left" />
        </div>
     { /*  <div className="mobile-ads">
          <AdSlot sizes={[[320, 50], [300, 50]]} adUnit="homepage/mobile" />
        </div> */ }
    </DFPSlotsProvider>   
    );
  }
}  

class AdSidebarRight extends Component {
  render() {
    return (
      <DFPSlotsProvider dfpNetworkId="72040985">
        <div className="desktop-ads">
          <AdSlot slotId="div-gpt-ad-1547481529413-0" sizes={[[300, 600], [160, 600]]} adUnit="sy_sidebar_right" />
        </div>
        <div className="mobile-ads">
          <AdSlot sizes={[[320, 50], [300, 50]]} adUnit="homepage/mobile" />
        </div>
      </DFPSlotsProvider>
    );
  }
}



export { AdTop, SyTop, AdSidebarLeft, AdSidebarRight }