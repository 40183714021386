import React, { Component } from 'react';

class MatchComments extends Component {
 

  render() { 
    
    let matchDetails = this.props.matchDetails;

    let rows = matchDetails.matchComments.map((comment) => {
    
    return (

          <li className="comment"><span class="minute">{comment.minute}</span> <span class="comment">{comment.comment}</span> </li>

        )
 
    });

    return ( 
    
        <div>
         <ul className="matchcomments">
         {rows}
         </ul>
       </div>
 
     )
 

}

}

  export default MatchComments;