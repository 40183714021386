import React, { Component } from 'react';
import i18n from '../i18n';

class Lineups extends Component {
  renderFormation = (match, homeTeamFormation, awayTeamFormation) => {
    return (
      <div className="formations">
      <div className="formations_info">{i18n.t('match.formation')} {match.home_team_formation} </div>
      <div className='formation'>
        <div key='homeFormation' className={"formation-home formation-" + match.home_team_formation}>
         
          {homeTeamFormation}
        </div>
        <div key='awayFormation' className={"formation-away formation-" + match.visiting_team_formation}>
          {awayTeamFormation}
        </div>
      </div>
      <div className="formations_info">{i18n.t('match.formation')} {match.visiting_team_formation} </div>
      </div>
      
    )
  }

  renderStartingLineups = (homeTeamName, homeTeamLineup, awayTeamName, awayTeamLineup) => {
    return (
      <span>
        <h4 className="tab-subheader">{i18n.t('match.starting_lineups')}</h4>
        <div key='startingLineup' className="lineups">
          <div key='homeLineup' className='teamLinup'>
            <ul key='homeLineupList'>
              <li className="team-name">{homeTeamName}</li>
              {homeTeamLineup}
            </ul>
          </div>
          <div key='awayLineup' className='teamLinup away'>
            <ul key='awayLineupList'>
              <li className="team-name">{awayTeamName}</li>
              {awayTeamLineup}
            </ul>
          </div>
        </div>
      </span>
    )
  }

  renderSubstitutes = (homeTeamSubs, awayTeamSubs) => {
    return (
      <span>
        <h4 className="tab-subheader">{i18n.t('match.substitutes')}</h4>
        <div key='lineupSubs' className="lineups">
          <div key='homeSubs' className='teamLinup'>
            <ul key='homeSubsList'>
              {homeTeamSubs}
            </ul>
          </div>
          <div key='awaySubs' className='teamLinup away'>
            <ul key='awaySubsList'>
              {awayTeamSubs}
            </ul>
          </div>
        </div>
      </span>

    )
  }

  renderMissingPlayers = (homeMissingPlayers, awayMissingPlayers) => {
    return (
      <span>
        <h4 className="tab-subheader">{i18n.t('match.missing_players')}</h4>
        <div key='lineupMissing' className="lineups">
          <div key='homeMissing' className='teamLinup'>
            <ul key='homeMissingList'>
              {homeMissingPlayers}
            </ul>
          </div>
          <div key='awayMissing' className='teamLinup away'>
            <ul key='awayMissingList'>
              {awayMissingPlayers}
            </ul>
          </div>
        </div>
      </span>
      
    )
  }

	render() {
    let match = this.props.match;
		let matchDetails = this.props.matchDetails;

    let homeTeamName = match.home_team.name;
    let homeTeamId = match.home_team.id;
		let homeTeamLineup = [];
		let homeTeamLineupCounter = 0;
		let homeTeamSubs = [];
		let homeTeamSubsCounter = 0;
    let homeTeamFormation = [];
    let homeTeamFormationCounter = 0;
    let homeTeamFormationNumberCounter = 1;
    let homeMissingPlayers = [];
    let homeMissingPlayersCounter = 0;

    let awayTeamName = match.visiting_team.name;
    let awayTeamId = match.visiting_team.id;
		let awayTeamLineup = [];
		let awayTeamLineupCounter = 0;
    let awayTeamSubs = [];
    let awayTeamSubsCounter = 0;
    let awayTeamFormation = [];
    let awayTeamFormationCounter = 0;
    let awayTeamFormationNumberCounter = 1;
    let awayMissingPlayers = [];
    let awayMissingPlayersCounter = 0;

		matchDetails.lineups.forEach(function(lineup) {
      let playerName = lineup.player.name;
      let playerLastName = playerName.substr(0, playerName.indexOf(',') === -1 ? playerName.length : playerName.indexOf(','));

      /*

home_team_gk_hstripes_color: "ff9428"
home_team_gk_main_color: "ff6317"
home_team_gk_number_color: "000000"
home_team_gk_sleeve_color: "ff9f40"
home_team_gk_split_color: null
home_team_gk_squares_color: null
home_team_gk_vstripes_color: null
home_team_hstripes_color: null

home_team_main_color: "dd0000"
home_team_number_color: "ffffff"
home_team_score: 0
home_team_sleeve_color: "dd0000"
home_team_split_color: null
home_team_squares_color: null
home_team_vstripes_color: null
*/


     if(match.home_team_vstripes_color !== null) { 

      var homeColors = {
 
        background: 'linear-gradient(90deg, #' + match.home_team_main_color + ' 50%, #' + match.home_team_vstripes_color + ' 50%)',
       color: '#' + match.home_team_number_color,
        borderColor: '#' + match.home_team_sleeve_color
      };
      
     } if(match.home_team_hstripes_color !== null) { 

      var homeColors = {
 
        background: 'linear-gradient(45deg, #' + match.home_team_main_color + ' 50%, #' + match.home_team_hstripes_color + ' 50%)',
       color: '#' + match.home_team_number_color,
        borderColor: '#' + match.home_team_sleeve_color
      };
      
     } else { 

     var homeColors = {
 
        background: '#' + match.home_team_main_color,
        color: '#' + match.home_team_number_color,
        borderColor: '#' + match.home_team_sleeve_color
      };

    }

    if(match.visiting_team_vstripes_color !== null) { 

      var awayColors = {
 
        background: 'linear-gradient(90deg, #' + match.visiting_team_main_color + ' 50%, #' + match.visiting_team_vstripes_color + ' 50%)',
        color: '#' + match.visiting_team_number_color,
        borderColor: '#' + match.visiting_team_sleeve_color
      };
      
     } if(match.visiting_team_hstripes_color !== null) { 

      var awayColors = {
 
        background: 'linear-gradient(45deg, #' + match.visiting_team_main_color + ' 50%, #' + match.visiting_team_hstripes_color + ' 50%)',
       color: '#' + match.visiting_team_number_color,
        borderColor: '#' + match.visiting_team_sleeve_color
      };
   
     } else { 

      var awayColors = {
 
        background: '#' + match.visiting_team_main_color,
        color: '#' + match.visiting_team_number_color,
        borderColor: '#' + match.visiting_team_sleeve_color
 
      };

    }

      
			if(lineup.player_team === 1) {
				if(lineup.substitute === true) {
            homeTeamSubs[homeTeamSubsCounter] = <li key={lineup.id}>
            
				                                    <span className="number">{lineup.shirt_number}</span>
				                                    <span className="player-name">{playerName}</span>
				                                </li>
            homeTeamSubsCounter++;
        } else {
            let goalKeeper = lineup.position === 1 ? ' (GK)' : '';
            homeTeamLineup[homeTeamLineupCounter] = <li key={lineup.id}>
							                                        <span className="number">{lineup.shirt_number}</span>
							                                        <span className="player-name">{playerName + goalKeeper}</span>
							                                    </li>
            homeTeamLineupCounter++;

            homeTeamFormation[homeTeamFormationCounter] =
                                    <div key={lineup.id} className={"pos player" + homeTeamFormationNumberCounter}>
                                       <div style={homeColors} className="player">{lineup.shirt_number}
                                          <div className="player-name">{playerLastName}</div>
                                       </div>

                                    </div>
            homeTeamFormationNumberCounter++;
            homeTeamFormationCounter++;
        }
			} else {
				if(lineup.substitute === true) {
          awayTeamSubs[awayTeamSubsCounter] = <li key={lineup.id}>
                                  <span className="number">{lineup.shirt_number}</span>
                                  <span className="player-name">{playerName}</span>
                              </li>
          awayTeamSubsCounter++;
        } else {
          let goalKeeper = lineup.position === 1 ? ' (GK)' : '';
          awayTeamLineup[awayTeamLineupCounter] = <li key={lineup.id}>
                                      <span className="number">{lineup.shirt_number}</span>
                                      <span className="player-name">{playerName + goalKeeper}</span>
                                  </li>
          awayTeamLineupCounter++;

          awayTeamFormation[awayTeamFormationCounter] =
                                  <div key={lineup.id} className={"pos player" + awayTeamFormationNumberCounter}>
                                     <div style={awayColors} className="player">{lineup.shirt_number}
                                        <div className="player-name">{playerLastName}</div>
                                     </div>

                                  </div>
          awayTeamFormationNumberCounter++;
          awayTeamFormationCounter++;
        }
			}
    });
    
    matchDetails.missingPlayers.forEach(function(missing) {
    let playerName = missing.player_name;
    if(missing.team_id === homeTeamId) {
    homeMissingPlayers[homeMissingPlayersCounter] = <li key={missing.id}>
                                                      <span className="player-name">{playerName}</span>
                                                    </li>
    homeMissingPlayersCounter++;
    }
    });


    matchDetails.missingPlayers.forEach(function(missing) {
    let playerName = missing.player_name;
    if(missing.team_id === awayTeamId) {
    awayMissingPlayers[awayMissingPlayersCounter] = <li key={missing.id}>
                                                      <span className="player-name">{playerName}</span>
                                                    </li>
     awayMissingPlayersCounter++;
    }
      });

		return (
			<div key='lineups' className="lineup-box">
        {
          match.home_team_formation !== null ?
            this.renderFormation(match, homeTeamFormation, awayTeamFormation) :
            this.renderStartingLineups(homeTeamName, homeTeamLineup, awayTeamName, awayTeamLineup)
        }
        
         {
           homeTeamLineupCounter > 0 ?
           this.renderStartingLineups(homeTeamName, homeTeamLineup, awayTeamName, awayTeamLineup) :
           null
         }

        { 
          homeTeamSubsCounter > 0 ?
          this.renderSubstitutes(homeTeamSubs, awayTeamSubs) :
          null
        }

{ 
          //homeMissingPlayersCounter > 0 ? awayMissingPlayersCounter > 0 
          this.renderMissingPlayers(homeMissingPlayers, awayMissingPlayers) 
         
        }
        
			</div>
		)
	}  
}

export default Lineups;

 