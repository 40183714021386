import React, { Component } from 'react';

//const monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

class WidgetMatch extends Component {
	render() {
		const match = this.props.match;
		let dateHeader = null;
		let minute = '';
		let statusDiv = '';
		var matchDate = new Date(match.match_date);
    var time = matchDate.getHours() + ':' + ("0" + matchDate.getMinutes()).slice(-2);
    //var month = monthNames[matchDate.getMonth()];
    let homeScore = match.home_team_score === null ? '' : match.home_team_score;
    let awayScore = match.visiting_team_score === null ? '' : match.visiting_team_score;

    /*if(this.props.lastDay !== null) {
    	dateHeader = matchDate.getDate() + '. ' + month;
    }*/

    if (match.status === 'POSTPONED') {
        statusDiv = <abbr title="Postponed">PPD</abbr>;
    } else if (match.status === 'UPCOMING') {
        statusDiv = <span className="time">{time}</span>;
    } else if (match.status === 'ONGOING') {
        if(typeof match.currentPeriod !== 'undefined' && match.currentPeriod !== null) {
            minute = match.currentPeriod.minute;
            let currentPeriod = match.currentPeriod.text;
            var matches = currentPeriod.match(/\b(\w)/g);
            var acronym = matches.join('');

            if(minute === '') {
                if(currentPeriod === 'Pause') {
                    minute = currentPeriod;
                } else {
                    minute = acronym.toUpperCase();
                }
            }
        }
        statusDiv = minute;
    } else if (match.status === 'FINISHED') {
        if(typeof match.currentPeriod === 'undefined' || match.currentPeriod == null) {
            if(match.finished_time_status === 'ORDINARY_TIME') {
                minute = 'FT';
            } else if(match.finished_time_status === 'OVERTIME') {
                minute = 'AET';
            } else if(match.finished_time_status === 'PENALTY_SHOOTOUT') {
                minute = 'P';
            }
        } else {
            minute = match.currentPeriod.minute;
        }

        statusDiv = 'FT';
    }

		return (
			<li>
                <div className='matchRow'>
    				<div className="status">
                      {statusDiv}
                    </div>
            		<div className="teams">
                      <div>{match.home_team.name}</div>
                      <div>{match.visiting_team.name}</div>
                    </div>
                    <div className="total">
                      <div className="home-score">{homeScore}</div>
                      <div className="away-score">{awayScore}</div>
                    </div>
                </div>
			</li>
		);
	}
}

export default WidgetMatch;

 