import axios from 'axios';

export const SET_CATEGORIES = 'SET_CATEGORIES';

export function setCategories(categories) {
    return {
        type: SET_CATEGORIES,
        categories
    }
}

export function getCategories(sportId, callback) {
    return dispatch => {
        return axios.get('https://data.scorecenter.live/categories', {
                params: {
                    sportId: sportId
                }
            })
            .then(res => {
                //callback();
                //console.log(res.data);
                dispatch(setCategories(res.data));
            })
            .catch(err => {
                console.log(err);
            });
    }
}

